<template>
  <common-page-table
    :apiName="apiName"
    :nameRole="nameRoleEntity"
    :titleTable="$t('Контакты')"
    :paramsGetData="paramsGetData"
    :fieldLand="fieldLang"
    :hasLang="false"
    :alternativeConverter="convertData"
    :isDefaultConverter="false"
    :prefixEdit="
      `/contract/${$route.params.ContractId}/person/${$route.params.PersonId}/contact`
    "
    :prefixShow="
      `/contract/${$route.params.ContractId}/person/${$route.params.PersonId}/contact`
    "
    :linkCreate="
      `/contract/${$route.params.ContractId}/person/${$route.params.PersonId}/contact/create`
    "
    :keyTable="'id'"
    :deleteParams="deleteParams"
    :apiDeleteName="apiDeleteName"
  />
</template>
<script>
import { NAME_OBJECT } from "../../../../../RoleConstanans";
import CommonPageTable from "../../../commonElement/CommonPageTable.vue";
import {API_METHODS, FIELD_LANG_PERSON} from "../../../../../constant";
import convertLangDataToTable from "../../../../../helpers/converters/convertLangDataToTable";
import formatData from "../../../../../helpers/converters/formatData";
export default {
  name: "PersonContact",
  components: { CommonPageTable },
  data() {
    return {
      fieldLang: "",
      apiName: API_METHODS.GET_ALL_CONTACT,
      apiDeleteName: API_METHODS.DELETE_CONTACT
    };
  },
  computed: {
    paramsGetData() {
      return { SubjectId: this.$route.params.PersonId };
    },
    nameRoleEntity() {
      return NAME_OBJECT.clientPersonContact;
    }
  },
  methods: {
    deleteParams(id) {
      return { SubjectId: this.$route.params.PersonId, id: id };
    },
    convertData(dataNow, langNow) {
      let res = convertLangDataToTable(dataNow, langNow);
      res = res.map(person => {
        return { ...person, isPrimary: person.isPrimary? this.$t('Да') :  this.$t('Нет')};
      });
      return res;
    },
  }
};
</script>
